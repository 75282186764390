<template>
  <div class="">
    <div class="s-layout__index_header">
      <div class="btn-group" role="group">
        <button
          type="button"
          @click="$router.push({ name: 'credits-index' })"
          class="btn"
          :class="$route.name == 'credits-index' ? 'active' : ''"
        >
          <i class="bi bi-currency-bitcoin"></i>

          Crédits clients
        </button>

        <button
          type="button"
          @click="$router.push({ name: 'credits-suppliers-index' })"
          class="btn"
          :class="$route.name == 'credits-suppliers-index' ? 'active' : ''"
        >
          <i class="bi bi-currency-bitcoin"></i>

          Crédits fournisseurs
        </button>

        <button
          type="button"
          @click="$router.push({ name: 'credits-balances' })"
          class="btn"
          :class="$route.name == 'credits-balances' ? 'active' : ''"
        >
          <i class="bi bi-chat-left-dots-fill"></i>

           Solde créditeur
        </button>

      </div>
    </div>

    <br />
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  mounted() {
    window.location;
  },
};
</script>
